<template>
  <section class="px-4 relative top-20 pb-32 md:pb-40 md:pt-20 pt-12">
    <div class="container">
      <div class="max-w-[960px] w-full mx-auto">
        <h1 class="font-bold text-agra-green-dark font-montserrat mb-10 lg:mb-12 text-3xl md:text-5xl w-full">Terms and conditions
        </h1>
        <h2 class="text-4xl text-agra-green-dark font-semibold border-b-2 border-agra-green pb-12 mb-12">
          Terms
        </h2>
        <p class="mb-6">
          To be written...
        </p>
    
        <img src="/images/hero.jpg" alt="hero" class="w-full block my-6">

        <p class="mb-6"></p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState([
      'staticContent'
    ])
  }
}
</script>
